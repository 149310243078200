#threadContent{
    height: 100vh;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    color: white !important;
}

.contentAttachments {
    height: 40px;
    padding: 0px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.contentImage {
    max-width: 100vw;
    max-height: calc(100vh - 140px);
}

.imoframe {
    max-width: 100vw;
    width: 100%;
    height: calc(100vh - 140px);
    max-height: calc(100vh - 180px);
    margin-top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    color: white !important;
    border: 1px solid black;;
}
.contentText {
    max-width: 100vw;
    max-height: calc(100vh - 180px);
    margin-top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
    color: white !important;
}

.contentUrl {
    width: 100vw;
    height: calc(100vh - 120px);
    margin-top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    border: none;
}

.contentVideoContainer{
    width: 100vw;
    height: calc(100vh - 120px);
    margin-top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    border: none;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
}

.contentVideo {
    width: 100vw;
    max-width: 100vw;
    max-height: calc(100vh - 120px);
}