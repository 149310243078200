#header {
    height: 60px + var(--sab) + var(--sat);
    display: flex; 
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    padding: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: var(--sat);
}

#brand {
    display: inline-block;
    font-size: 24px;
}

.nav-input {
    padding: 5px;
    border: 1px solid rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.1);
    border-radius: 5px;
    display: flex; 
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    /* width: 100%; */
    width: 80%;
    /* max-width: 250px; */
    max-width: calc(100vw - 130px);
}

.nav-input-prefix {
    margin-right: 2px;
    color: rgba(255,255,255,0.5);
}

.nav-input-cmp {
    background: none;
    border: none;
    outline: none;
    outline-width: 0px;
    color: rgba(255,255,255,0.5);
    font-size: 14px;
    /* width: 170px; */
    width: 100%;
}

.nav-input-cmp:focus {
    color: white;
}

.back-btn {
    margin-right: 10px;
}