.comment {
    border-left: 1px solid #888;
    padding-left: 10px;
}

.replies {
    margin-left: 10px;
}

.comment_author {
    font-size: 0.7em;
    color: #424242;
}

.commentBody {
    font-size: 14px;
}