.post {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    border-top:1px solid rgba(0,0,0,0.05);
}

.postMeta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    padding: 5px;
    width: 90px;
}

.postMeta > span {
    text-align: center;
}

.thumbnail {
    height: 60px;
    width: 80px;
    background-color: #ffffff;
    margin: 5px;
}


.postData {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100vw - 120px);
    padding: 10px;
}

.subData {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 19px;
    margin-top: 10px;
}

.title {
    font-size: 0.9em;
    font-weight: bold;
    margin: 0;
    width: 100%;
    text-align: left;
}

.upvoteRatio {
    font-size: 0.8em;
    color: #888;
    margin: 0;
    width: 100%;
    text-align: left;
    justify-items: center;
    justify-content:center;
    align-items: center;
    align-content: center;
    column-gap:10px;
}

.subreddit {
    font-size: 0.6em;
    color: #888;
    margin: 0;
    width: 100%;
    text-align: left;
}

.nsfwBadge {
    font-size: 10px !important;
}

.awardsBadge{ 
    margin-top: 10px;
}

.author {
    font-size: 0.7em;
    color: #ffffff;
    margin: 0;
    text-align: center;
    background: #515252;
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 2px;
    display: flex;
    justify-items: center;
    justify-content:center;
    align-items: center;
    align-content: center;;
    column-gap:5px;
}

.time {
    font-size: 0.7em;
    color: #888;
    margin: 0;
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 2px;
    text-align: center;
    display: flex;
    justify-items: center;
    justify-content:center;
    align-items: center;
    align-content: center;;
    column-gap:5px;
}

.comments {
    font-size: 0.7em;
    color: #ffffff;
    margin: 0;
    text-align: center;
    background: #10adeb;
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 2px;

    display: flex;
    justify-items: center;
    justify-content:center;
    align-items: center;
    align-content: center;
    column-gap:5px;
}

.flair {
    font-size: 0.7em;
    color: #ffffff;
    margin: 0;
    width: 100%;
    text-align: center;
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 2px;
}