
.commentsContainer {
    width: calc(100vw -20px);
    height: calc(100vh - 140px);
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: all;
}

.commentControls {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    justify-items: center;

}

.arrow, .refresh{
    font-size: 20px;
}

.selected-comment {
    border-left: 2px solid #0079d3;
    background: rgba(131, 168, 255, 0.1);
    font-weight: bold;
}

.commentMeta{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    justify-items: center;
    font-size: 0.7em;
    color: #aaa;
}

.commentSubMeta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    justify-items: center;
    font-size: 0.7em;
    color: #aaa;
    margin-bottom: 10px;
}

.commentBody {
    font-size: 0.8em;
}

.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    height: 100%;
    padding-top: calc(100vh / 2 - 150px);
    color: #aaaaaa;
}

.loading-icon {
    font-size: 72px;
    margin: 20px;
    color: #aaaaaa;
    animation: bounce 1s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
 } 