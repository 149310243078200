/* #thread-drawer {
    height: 100vh;
} */
/* 
#thread-content {
    height: calc(100vh - 56px);
} */

.threadControls{
    /* background: white; */
    color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.threadHeader {
    padding-top: 20px;
    margin-top: calc(var(--sab) + var(--sat))
}

.back_arrow {
    color: white;
    font-size: 18px;
    margin-left: -20px;
}

.subcontrols {
    font-size: 22px;
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    padding-right: 7px;
}