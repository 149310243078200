
.galleryImage {
    max-width: 100%;
    max-height: calc(100vh - 180px);
}

.galleryControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
}