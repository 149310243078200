#feed {
    width: 100%;
    height: calc(100vh - 112px - var(--sab) - var(--sat));
}

.postsContainer {
    width: 100%;
    height: calc(100vh - 116px - var(--sab) - var(--sat));
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.loadingskeley {
    margin: 10px;
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    column-gap: 10px;
}

.skeleys {
    overflow-y: auto;
    height: calc(100vh - 120px - var(--sab) - var(--sat));
}


.pwaBanner {
    width: 100vw;
    background: #0079d3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    justify-items: center;
    padding: 20px;
    color: white;
}


.pwaBannerText {
    font-size: 0.8em;
    font-weight: bold;
    margin: 0;
    text-align: left;
    padding: 10px;
    text-align: center;
    line-height: 1.5em;
    width: 100vw;
}

.pwaInstallBtn {
    background: white;
    color: #0079d3;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.8em;
    font-weight: bold;
    margin: 0;
    text-align: left;
    padding: 10px;
    text-align: center;
    line-height: 1.5em;
    width: calc(100vw - 40px);
    cursor: pointer;
}


.noData {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    justify-content: center;
    align-content: center;
    justify-items: center;
    row-gap: 30px;
    color: #888;
}

.noDataIcon {
    font-size: 100px;
    color: #888;
}