
.summaryContainer {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: calc(100vh - 120px);
    padding: 10px;
}

.summaryContainer > * {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
}

.articleSource {
    font-size: 10px;
    color: #aaa;
    font-style: italic;
    margin-bottom: 20px;
}

.not_parsable{ 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    justify-items: center;
    height: 100%;
    width: 100%;
    row-gap: 20px;
}