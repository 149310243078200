.root {
  background: #000000;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 3px;
}

.playPause {
  cursor: pointer;
}
.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  z-index: 5;
  background: black;
  color: white;
}

video {
  width: 100%;
  max-height: 90vh !important;
}

.controls {
  position: absolute;
  bottom: 0px;
  width: 90%;
  height: 30px;
  z-index: 2;
  border-radius: 5px;
  transition: all 250ms ease-in;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  opacity: 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.controls-open {
  background: rgba(255, 255, 255, 0.6);
  opacity: 1;
  bottom: 10px;
}

.playPause > svg {
  fill: black;
  width: 10px;
  height: 10px;
}
.track {
  width: 80%;
  height: 5px;
  background: black;
  border-radius: 5px;
}

.progress {
  border-radius: 5px;
  height: 100%;
  width: 32%;
  background: #c0c0c0;
  animation: pulse 8s infinite;
  pointer-events: none;
}

.time {
  font-size: 10px;
}

audio {
  display: none;
}

.errors {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  color: #00ff00;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.logo {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  color: rgba(255, 255, 255, 0.5);
  font-size: 28px;
  text-transform: lowercase;
}

@keyframes pulse {
  0%,
  100% {
    background-color: #f56a3f;
  }
  50% {
    background-color: #9e42b0;
  }
}
