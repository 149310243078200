
.desktop-post {
    min-height: calc(100vh / 2);
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.desktop-post-card {
    width: calc(min(800px, 100vw));
    min-height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    border: 1px solid #e6e6e6;
    margin-bottom: 1rem;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}


.desktop-post-card-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid #e6e6e6;
    /* background-color: #fff; */
    width: calc(100% - 40px);
    height: 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.desktop-post-card-header {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #e6e6e6;
    /* background-color: #fff; */
    width: calc(100% - 20px);
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.desktop-post-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    min-height: calc(100% - 50px - 50px);
}

.post-preview {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0px;
}

.desktop-post-contentImage-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.desktop-post-contentImage {
    width: auto;
    height: 100%;
    border: none;
    border-radius: 10px;
}

.desktop-post-card-header-nsfw {
    padding: 2px;
    font-size: 10px;
    font-weight: bold;
    color: white;
    background: rgb(222, 7, 7);
    border-radius: 5px;
    margin-left: 5px;
}